"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parser = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const ethers_1 = require("ethers");
const _1 = require(".");
const ArbOutbox_1 = require("./abi/ArbOutbox");
const ArbProxy_1 = require("./abi/ArbProxy");
const ArbRetryableTx_1 = require("./abi/ArbRetryableTx");
const ArbSys_1 = require("./abi/ArbSys");
const L1ArbitrumGateway_1 = require("./abi/L1ArbitrumGateway");
const L1OrbitCustomGateway_1 = require("./abi/L1OrbitCustomGateway");
const L2ArbitrumGateway_1 = require("./abi/L2ArbitrumGateway");
const ARB_OUTBOX_CONTRACT = '0x0B9857ae2D4A3DBe74ffE1d7DF045bb7F96E4840';
const ARB_SYS_CONTRACT = '0x0000000000000000000000000000000000000064';
const ARBITRUM_L2_ERC20_GATEWAY_PROXY = '0x09e9222E96E7B4AE2a407B98d48e330053351EEe';
const ARB_RETRYABLE_TX_CONTRACT = '0x000000000000000000000000000000000000006e';
const L2_ARBITRUM_CUSTOM_GATEWAY_CONTRACT = '0x096760F208390250649E3e8763348E783AEF5562';
const L2_ARBITRUM_GATEWAY_CONTRACT = '0x5288c571Fd7aD117beA99bF60FE0846C4E84F933';
const L1_ARBITRUM_GATEWAY_CONTRACT = '0x72ce9c846789fdb6fc1f34ac4ad25dd9ef7031ef';
const L1_ORBIT_CUSTOM_GATEWAY_CONTRACT = '0x4Dbd4fc535Ac27206064B68FfCf827b0A60BAB3f';
class Parser {
    constructor(args) {
        this.arbProxyAbi = new ethers_1.ethers.Interface(ArbProxy_1.ARB_PROXY_ABI);
        this.arbSysAbi = new ethers_1.ethers.Interface(ArbSys_1.ARB_SYS_ABI);
        this.arbOutboxAbi = new ethers_1.ethers.Interface(ArbOutbox_1.ARB_OUTBOX_ABI);
        this.arbRetryableTxAbi = new ethers_1.ethers.Interface(ArbRetryableTx_1.ARBITRUM_RETRYABLE_TX_ABI);
        this.l2ArbitrumGatewayAbi = new ethers_1.ethers.Interface(L2ArbitrumGateway_1.L2_ARBITRUM_GATEWAY_ABI);
        this.l1OrbitCustomGatewayAbi = new ethers_1.ethers.Interface(L1OrbitCustomGateway_1.L1_ORBIT_CUSTOM_GATEWAY_ABI);
        this.l1ArbitrumGatewayAbi = new ethers_1.ethers.Interface(L1ArbitrumGateway_1.L1_ARBITRUM_GATEWAY_ABI);
        this.chainId = args.chainId;
    }
    async parse(tx) {
        if (!tx.inputData)
            return;
        const txSigHash = (0, _1.getSigHash)(tx.inputData);
        const selectedAbi = (() => {
            if ((0, _1.txInteractsWithContract)(tx, ARB_OUTBOX_CONTRACT))
                return this.arbOutboxAbi;
            if ((0, _1.txInteractsWithContract)(tx, ARB_SYS_CONTRACT))
                return this.arbSysAbi;
            if ((0, _1.txInteractsWithContract)(tx, L2_ARBITRUM_GATEWAY_CONTRACT))
                return this.l2ArbitrumGatewayAbi;
            if ((0, _1.txInteractsWithContract)(tx, L2_ARBITRUM_CUSTOM_GATEWAY_CONTRACT))
                return this.l2ArbitrumGatewayAbi;
            if ((0, _1.txInteractsWithContract)(tx, ARBITRUM_L2_ERC20_GATEWAY_PROXY))
                return this.l2ArbitrumGatewayAbi;
            if ((0, _1.txInteractsWithContract)(tx, L1_ARBITRUM_GATEWAY_CONTRACT) &&
                this.chainId === caip_1.arbitrumChainId)
                return this.arbProxyAbi;
            if ((0, _1.txInteractsWithContract)(tx, ARB_RETRYABLE_TX_CONTRACT))
                return this.arbRetryableTxAbi;
            if ((0, _1.txInteractsWithContract)(tx, L1_ORBIT_CUSTOM_GATEWAY_CONTRACT))
                return this.l1OrbitCustomGatewayAbi;
            if ((0, _1.txInteractsWithContract)(tx, L1_ARBITRUM_GATEWAY_CONTRACT))
                return this.l1ArbitrumGatewayAbi;
        })();
        const decoded = selectedAbi?.parseTransaction({ data: tx.inputData });
        // failed to decode input data
        if (!decoded)
            return;
        const maybeAssetId = tx.tokenTransfers?.[0].contract
            ? (0, caip_1.toAssetId)({
                chainId: this.chainId,
                assetNamespace: 'erc20',
                assetReference: tx.tokenTransfers?.[0].contract,
            })
            : undefined;
        const data = {
            assetId: maybeAssetId,
            method: decoded.name,
            parser: 'arbitrumBridge',
        };
        // On ethereum side, we wants to tag it as a deposit if it interacts with the L1 Arbitrum Gateway
        if ((0, _1.txInteractsWithContract)(tx, L1_ARBITRUM_GATEWAY_CONTRACT) && this.chainId === caip_1.ethChainId) {
            data.method = `${decoded.name}Deposit`;
        }
        switch (selectedAbi) {
            case this.arbSysAbi:
                switch (txSigHash) {
                    case this.arbSysAbi.getFunction('withdrawEth').selector:
                        return await Promise.resolve({
                            data: {
                                ...data,
                                destinationAddress: decoded.args.destination,
                                destinationAssetId: caip_1.ethAssetId,
                                value: tx.value,
                            },
                        });
                    default:
                        return await Promise.resolve({ data });
                }
            case this.l2ArbitrumGatewayAbi:
                switch (txSigHash) {
                    case this.l2ArbitrumGatewayAbi.getFunction('outboundTransfer(address,address,uint256,bytes)').selector:
                        {
                            const amount = decoded.args._amount;
                            const l1Token = decoded.args._l1Token;
                            const destinationAssetId = (0, caip_1.toAssetId)({
                                chainId: caip_1.ethChainId,
                                assetNamespace: 'erc20',
                                assetReference: l1Token,
                            });
                            return await Promise.resolve({
                                data: {
                                    ...data,
                                    destinationAddress: decoded.args._to,
                                    destinationAssetId,
                                    value: amount.toString(),
                                },
                            });
                        }
                    case this.l2ArbitrumGatewayAbi.getFunction('finalizeInboundTransfer').selector:
                        return await Promise.resolve({
                            data: {
                                ...data,
                                // `finalizeInboundTransfer` on the Ethereum side (i.e withdraw request) is internal to the bridge, and only releases fundus safu
                                // https://docs.arbitrum.io/build-decentralized-apps/token-bridging/token-bridge-erc20
                                // however, on the Arbitrum side, it's an effective deposit
                                method: this.chainId === caip_1.arbitrumChainId ? 'finalizeInboundTransferDeposit' : data.method,
                            },
                        });
                    default:
                        return await Promise.resolve({ data });
                }
            default:
                return await Promise.resolve({ data });
        }
    }
}
exports.Parser = Parser;
