"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertValidTrade = void 0;
const sdk_1 = require("@arbitrum/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const types_1 = require("@shapeshiftoss/types");
const build_1 = require("@sniptt/monads/build");
const viem_1 = require("viem");
const chains_1 = require("viem/chains");
const types_2 = require("../../../types");
const utils_1 = require("../../../utils");
const types_3 = require("./types");
const assertValidTrade = async ({ buyAsset, sellAsset, getEthersV5Provider, }) => {
    if (!types_3.arbitrumBridgeSupportedChainIds.includes(sellAsset.chainId) ||
        !types_3.arbitrumBridgeSupportedChainIds.includes(buyAsset.chainId)) {
        return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `[ArbitrumBridge: assertValidTrade] - unsupported chainId`,
            code: types_2.TradeQuoteError.UnsupportedChain,
            details: { buyAsset, sellAsset },
        }));
    }
    if (buyAsset.chainId === sellAsset.chainId) {
        return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `[ArbitrumBridge: assertValidTrade] - both assets must be on different chainIds`,
            code: types_2.TradeQuoteError.UnsupportedTradePair,
            details: { buyAsset, sellAsset },
        }));
    }
    const isDeposit = sellAsset.chainId === caip_1.ethChainId;
    const isEthBridge = isDeposit
        ? sellAsset.assetId === caip_1.ethAssetId
        : sellAsset.assetId === caip_1.arbitrumAssetId;
    const isTokenBridge = !isEthBridge;
    if (isEthBridge) {
        const isInvalidPair = isDeposit
            ? buyAsset.assetId !== caip_1.arbitrumAssetId
            : buyAsset.assetId !== caip_1.ethAssetId;
        if (isInvalidPair) {
            return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
                message: `[ArbitrumBridge: tradeQuote] - Invalid ETH bridge pair`,
                code: types_2.TradeQuoteError.UnsupportedTradePair,
                details: { buyAsset, sellAsset },
            }));
        }
    }
    if (isTokenBridge) {
        const childNetwork = await (0, sdk_1.getArbitrumNetwork)(chains_1.arbitrum.id);
        const bridger = new sdk_1.Erc20Bridger(childNetwork);
        const erc20ParentAddress = (0, caip_1.fromAssetId)((isDeposit ? sellAsset : buyAsset).assetId).assetReference;
        const erc20ChildAddress = (0, caip_1.fromAssetId)((isDeposit ? buyAsset : sellAsset).assetId).assetReference;
        const parentProvider = getEthersV5Provider(types_1.KnownChainIds.EthereumMainnet);
        const childProvider = getEthersV5Provider(types_1.KnownChainIds.ArbitrumMainnet);
        // Since our related assets list isn't exhaustive and won't cut it to determine the Parent <-> Child mapping, we double check that the bridge is valid
        // by checking against Arbitrum bridge's own mappings, which uses different sources (Coingecko, Gemini, Uni and its own lists at the time of writing)
        const arbitrumBridgeErc20ChildAddress = await bridger.getChildErc20Address(erc20ParentAddress, parentProvider);
        const arbitrumBridgeErc20ParentAddress = await bridger.getParentErc20Address(erc20ChildAddress, childProvider);
        if (!(0, viem_1.isAddressEqual)((0, viem_1.getAddress)(arbitrumBridgeErc20ParentAddress), (0, viem_1.getAddress)(erc20ParentAddress))) {
            return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
                message: `[ArbitrumBridge: tradeQuote] - Invalid Parent ERC20 address: ${erc20ParentAddress}`,
                code: types_2.TradeQuoteError.UnsupportedTradePair,
            }));
        }
        if (!(0, viem_1.isAddressEqual)((0, viem_1.getAddress)(arbitrumBridgeErc20ChildAddress), (0, viem_1.getAddress)(erc20ChildAddress))) {
            return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
                message: `[ArbitrumBridge: tradeQuote] - Invalid Child ERC20 address: ${erc20ChildAddress}`,
                code: types_2.TradeQuoteError.UnsupportedTradePair,
            }));
        }
    }
    return (0, build_1.Ok)(true);
};
exports.assertValidTrade = assertValidTrade;
